/* https://seantheme.com/hud/profile.html */

@font-face {
  font-family: "Font Awesome 6 Brands";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(./font/fa-brands-400.woff2) format("woff2")
}

@font-face {
  font-family: "Font Awesome 6 Free";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(./font/fa-regular-400.woff2) format("woff2")
}

@font-face {
  font-family: "Font Awesome 6 Free";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url(./font/fa-solid-900.woff2) format("woff2")
}

@font-face {
  font-family: "Font Awesome 5 Brands";
  font-display: block;
  font-weight: 400;
  src: url(./font/fa-brands-400.woff2) format("woff2")
}

@font-face {
  font-family: "Font Awesome 5 Free";
  font-display: block;
  font-weight: 900;
  src: url(./font/fa-solid-900.woff2) format("woff2")
}

@font-face {
  font-family: "Font Awesome 5 Free";
  font-display: block;
  font-weight: 400;
  src: url(./font/fa-regular-400.woff2) format("woff2")
}

@font-face {
  font-family: FontAwesome;
  font-display: block;
  src: url(./font/fa-solid-900.woff2) format("woff2")
}

@font-face {
  font-family: FontAwesome;
  font-display: block;
  src: url(./font/fa-brands-400.woff2) format("woff2")
}

@font-face {
  font-family: FontAwesome;
  font-display: block;
  src: url(./font/fa-regular-400.woff2) format("woff2");
  unicode-range: u+f003,u+f006,u+f014,u+f016-f017,u+f01a-f01b,u+f01d,u+f022,u+f03e,u+f044,u+f046,u+f05c-f05d,u+f06e,u+f070,u+f087-f088,u+f08a,u+f094,u+f096-f097,u+f09d,u+f0a0,u+f0a2,u+f0a4-f0a7,u+f0c5,u+f0c7,u+f0e5-f0e6,u+f0eb,u+f0f6-f0f8,u+f10c,u+f114-f115,u+f118-f11a,u+f11c-f11d,u+f133,u+f147,u+f14e,u+f150-f152,u+f185-f186,u+f18e,u+f190-f192,u+f196,u+f1c1-f1c9,u+f1d9,u+f1db,u+f1e3,u+f1ea,u+f1f7,u+f1f9,u+f20a,u+f247-f248,u+f24a,u+f24d,u+f255-f25b,u+f25d,u+f271-f274,u+f278,u+f27b,u+f28c,u+f28e,u+f29c,u+f2b5,u+f2b7,u+f2ba,u+f2bc,u+f2be,u+f2c0-f2c1,u+f2c3,u+f2d0,u+f2d2,u+f2d4,u+f2dc
}

@font-face {
  font-display: block;
  font-family: bootstrap-icons;
  src: url(./font/bootstrap-icons.woff2) format("woff2"),url(./font/bootstrap-icons.woff) format("woff")
}

a {
  color: -webkit-link;
  cursor: pointer;
  text-decoration: underline;
}

.pointer {
  cursor: pointer;
  transition: .3s;
}

.pointer.hover:hover {
  transform: scale(1.1);
}

.profile-tab.mobile {
  display: none;
}

@media (max-width: 991.98px) {
  .profile .profile-container .profile-sidebar {
    display: block!important;
  }

  .profile-tab {
    display: none!important;
  }
  .profile-tab.mobile {
    display: flex!important;
  }
  .profile .profile-container .profile-sidebar {
    width: 100%;
  }
  .profile .profile-img img {
    width: 80px;
  }
  .profile .profile-img {
    height: auto;
    text-align: center;
  }
}

* {
  scrollbar-width: none; /* Pour Firefox */
  -ms-overflow-style: none;  /* Pour Internet Explorer et Edge */
}

*::-webkit-scrollbar { /* Pour Chrome, Safari et Opera */
  display: none;
}